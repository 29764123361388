import { DEFAULT_LANDING_PAGE } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'widgets/landing-page/BaseLandingPage';
import SeoComponent from 'widgets/seo/SeoComponent';

import './index.css';

function IndexPage() {
  return (
    <BaseLandingPage
      title={DEFAULT_LANDING_PAGE.title}
      descripntion={DEFAULT_LANDING_PAGE.description}
      cta={DEFAULT_LANDING_PAGE.cta}
    />
  );
}

export default IndexPage;

export function Head() {
  return (
    <SeoComponent
      title={DEFAULT_LANDING_PAGE.seo.title}
      description={DEFAULT_LANDING_PAGE.seo.description}
      canonicalUrl="labs.goalskeeper.io/"
    />
  );
}
